import React, { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPhoneCode,
  checkPhoneCodeByPhone,
} from "../../../../actions/phoneVerification";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { sendCotizacion } from "../../service/cotizar";
import { useNavigate } from "react-router-dom";

export const ModalVerificationPhone = ({
  countryCode,
  phoneNumber,
  dataCar,
  textError,
  isDisabledB,
  setCurrentView,
  isGnc,
  kms,
  setPriceQuote,
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { token, name, email } = useSelector((state) => state.authReducer);
  const { phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e, inputName, index) => {
    setIsError(false);
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      setValue(inputName, value);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < inputRefs.current.length - 1 && value) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      setValue(inputName, "");
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("text");
    // Verifica si los datos pegados son de longitud 6 y solo contienen dígitos
    if (pastedData.length === 6 && /^[0-9]+$/.test(pastedData)) {
      pastedData.split("").forEach((digit, index) => {
        setValue(`code${index + 1}`, digit);
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = digit;
        }
      });
      inputRefs.current[5].focus(); // Focaliza el último input
    }
    e.preventDefault();
  };

  const handleVerification = async (data) => {
    setIsLoading(true);
    const code = Object.values(data).join("");
    if (setPriceQuote) {
      try {
        let response;
        if (token) {
          response = await checkPhoneCode(
            token,
            code,
            setIsError,
            dispatch,
            setIsLoading
          );
        } else {
          response = await checkPhoneCodeByPhone(
            phone,
            code,
            setIsError,
            dispatch,
            setIsLoading
          );
        }
        if (response) {
          sendCotizacion(
            dataCar?.brand?.name,
            dataCar?.brand?.id,
            dataCar?.model?.name,
            dataCar?.model?.id,
            dataCar?.version?.name,
            dataCar?.version?.id,
            dataCar?.year?.name,
            isGnc,
            kms,
            phone,
            name,
            email,
            setIsLoading,
            setCurrentView,
            setPriceQuote,
            navigate,
            dispatch
          );
        }
      } catch (e) {
        console.log("Error en checkPhone:", e);
      }
    } else {
      if (token) {
        checkPhoneCode(
          token,
          code,
          setIsError,
          dispatch,
          setIsLoading,
          setCurrentView
        );
      } else {
        checkPhoneCodeByPhone(
          phone,
          code,
          setIsError,
          dispatch,
          setIsLoading,
          setCurrentView
        );
      }
    }
  };

  return (
    <div
      className={isPageWide ? "card-contact-data" : "mobile-dropdown-container"}
    >
      <div className="card-contact-header">
        <p className="contact-header-title">Verificación telefónica.</p>
      </div>
      <div
        style={{
          padding: "12px",
          height: "364px",
          width: isPageWide ? "355px" : "100%",
        }}
      >
        <form
          onSubmit={handleSubmit(handleVerification)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " space-between",
            height: "100%",
          }}
        >
          <div style={{ marginTop: "10px" }}>
            <p className="phone-number-two">
              Ingresá el código que te enviamos por Whatsapp al{" "}
              <span className="phone-number-title">
                {phoneNumber ?? phone} 👇🏽
              </span>
            </p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onPaste={handlePaste} // Agrega el controlador de pegado
            >
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  maxLength="1"
                  className="code-input"
                  {...register(`code${index + 1}`)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleChange(e, `input${index + 1}`, index)}
                />
              ))}
            </div>
            {isError ? (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {textError}
              </p>
            ) : (
              <></>
            )}
          </div>
          {isLoading ? (
            <Spinner
              animation="border"
              style={{
                margin: "auto",
                color: " #7340d8",
                marginTop: "40px",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasePurpleButtonComponent
              buttonDisabled={isLoading}
              buttonText={"Continuar"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
