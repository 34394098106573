import React from "react";
import BaseModalAuthComponent from "./BaseModalAuthComponent";
import LoginComponent from "./LoginComponent";
import RegisterComponent from "./RegisterComponent";
import { TitleHomeWithIcon } from "../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

const ModalAuthComponent = ({
  setIsModalOpen,
  isModalOpen,
  toggleLoginModal,
  getInWithEmail,
  setGetInWithEmail,
  showLogin,
  setShowLogin,
  setCurrentView,
  isSale,
  isSaleMobile,
  handleButtonClick,
}) => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  return (
    <BaseModalAuthComponent
      show={isModalOpen}
      onClose={toggleLoginModal}
      getInWithEmail={getInWithEmail}
      setGetInWithEmail={setGetInWithEmail}
      showLogin={showLogin}
      setShowLogin={setShowLogin}
    >
      <div className="mt-1 mb-2 mb-md-1">
        <TitleHomeWithIcon
          title={!showLogin ? "Crear cuenta" : "Iniciar sesión"}
          fontSize={isPageWide ? "30px" : "22px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      </div>
      {!showLogin ? (
        <RegisterComponent
          setCurrentView={setCurrentView}
          isSale={isSale}
          isSaleMobile={isSaleMobile}
          handleButtonClick={handleButtonClick}
          setIsModalOpen={setIsModalOpen}
          setShowLogin={setShowLogin}
        />
      ) : (
        <LoginComponent
          getInWithEmail={getInWithEmail}
          setGetInWithEmail={setGetInWithEmail}
          setShowLogin={setShowLogin}
          setCurrentView={setCurrentView}
          isSale={isSale}
          isSaleMobile={isSaleMobile}
          handleButtonClick={handleButtonClick}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </BaseModalAuthComponent>
  );
};

export default ModalAuthComponent;
