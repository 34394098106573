import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendPhoneCode, sendPhoneCodeByPhone } from "../../../../actions/phoneVerification";
import { useNavigate } from "react-router-dom";
import { sendCotizacion } from "../../service/cotizar";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import { getUserByPhone, updateUserByPhone } from "../../../../actions/user";

export const ViewStepOneComponent = ({
  selectedOptions,
  isGnc,
  kms,
  setCurrentView,
  setPriceQuote,
  setPhoneNumber
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { token, name, email, isAuthenticated } = useSelector((state) => state.authReducer);
  const { phone, verified_phone } = useSelector((state) => state.userReducer);
  const { register, handleSubmit } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [isLoading, setIsLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();

  useEffect(() => {
    if (verified_phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [verified_phone]);

  const sendSale = (data) => {
    setIsLoading(true);
    const newPhone = `549${data.cod_area}${data.telefono}`;
    if (!isAuthenticated) {
      getUserByPhone(newPhone, setIsLoading, dispatch);
    } else {
      let updateUser = {};
      if(!name || name === "") updateUser.name = data.name;
      if(!email || email === "") updateUser.email = data.email;
      if(Object.keys(updateUser).length !== 0){
        updateUser.phone = phone;
        updateUserByPhone(updateUser, dispatch);
      };
      if (verified_phone) {
        sendCotizacion(
          selectedOptions?.brand?.name,
          selectedOptions?.brand?.id,
          selectedOptions?.model?.name,
          selectedOptions?.model?.id,
          selectedOptions?.version?.name,
          selectedOptions?.version?.id,
          selectedOptions?.year?.name,
          isGnc,
          kms,
          (phone && phone !== "")? phone: newPhone,
          (name && name !== "")? name: data.name,
          (email && email !== "")? email: data.email,
          setIsLoading,
          setCurrentView,
          setPriceQuote,
          navigate,
          dispatch
        );
      } else {
        const sendPhone = newPhone? newPhone: phone;
        if (setPhoneNumber) setPhoneNumber(sendPhone);
        if (token) {
          sendPhoneCode(token, sendPhone, dispatch);
        } else {
          sendPhoneCodeByPhone(sendPhone, dispatch);
        }
        setTimeout(() => {
          setCurrentView("step2");
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <>
      <div
        className={
          isPageWide ? "card-contact-data" : "mobile-dropdown-container"
        }
      >
        <div className="card-contact-header">
          <p className="contact-header-title">
            {(phone && name && email) ?
              "¡Estos son tus datos!"
              :
              "¡Completá tus datos!"
            }
          </p>
        </div>
        {showSpinner ? (
          <div style={{ marginTop: "50px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit(sendSale)}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Teléfono celular</label>
                    {phone ?
                      <p>+{phone}</p>
                      :
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className="phone-number">+549</p>
                        <input
                          type="text"
                          className="base-input"
                          placeholder="Ej: 11"
                          maxLength={3}
                          style={{ width: "65px", marginRight: "10px" }}
                          {...register("cod_area", {
                            required: true,
                          })}
                          onChange={(e) => {
                            setcodArea(e.target.value.length);
                          }}
                        />
                        <input
                          type="text"
                          className="base-input ms-1"
                          placeholder="Sin 15"
                          {...register("telefono", {
                            required: true,
                            maxLength: codArea ? Math.abs(codArea - 10) : 0,
                            minLength: codArea ? Math.abs(codArea - 10) : 0,
                          })}
                          maxLength={
                            codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                          }
                          minLength={
                            codArea ? Math.abs(parseInt(codArea.length) - 10) : 0
                          }
                        />
                      </div>
                    }
                    {isAuthenticated &&
                    <>
                      <div className="mb-2">
                        <label className="input-labels">Nombre</label>
                        {name?
                          <p>{name}</p>
                        :
                          <input
                            type="text"
                            className="base-input"
                            placeholder="Ingresá tu nombre"
                            {...register("name", {
                              required: true,
                              maxLength: 50,
                              minLength: 2,
                            })}
                          />
                        }
                      </div>
                      <div className="mb-2">
                        <label className="input-labels">Email</label>
                        {email?
                          <p>{email}</p>
                        :
                          <input
                            type="text"
                            className="base-input"
                            placeholder="Ingresá tu email"
                            {...register("email", {
                              required: true,
                              maxLength: 30,
                              minLength: 2,
                            })}
                          />
                        }
                      </div>
                    </>
                  }
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {!verified_phone === false &&
                    <p className="security-code-text">
                      Recibirás un código de seguridad.
                    </p>
                  }
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonText={isLoading ? "Enviando..." : "Continuar"}
                    buttonDisabled={isLoading ? true : false}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
