import React from 'react'
import { Modal } from 'react-bootstrap'
import BasePurpleButtonComponent from './BasePurpleButtonComponent'
import BaseSecondaryButtonComponent from './BaseSecondaryButtonComponent';

export default function ModalMinKmsComponent({
  show,
  setShow,
  kms,
  setIsContinuing,
  setSelectedButton,
  setCurrentView,
  isMobile,
  setShowModalForm
}) {
  const handleClose = () => setShow(false);
  const HandleYesButton = () => {
    setIsContinuing(true)
    if (isMobile) {
      setSelectedButton(6)
    } else {
      setShowModalForm(true)
    }
    setShow(false)
  }
  return (
    <Modal show={show} onHide={handleClose} size='sm' centered>
      <Modal.Header style={{ backgroundColor: 'white' }}>
        <p style={{ color: ' #7340d8', fontWeight: 'bold', fontSize: '20px' }}>El kilometraje ingresado es muy bajo</p>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'white' }}>
        <p> Ingresaste <strong> {kms}</strong> kms ¿Es correcto?</p>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: 'white' }}>
        <BaseSecondaryButtonComponent buttonText={'No'} onClick={handleClose} />
        <BasePurpleButtonComponent buttonText={'Si'} onClick={HandleYesButton} />
      </Modal.Footer>
    </Modal>
  )
}
