import React, { useState } from "react";
import { Carousel, Spinner } from "react-bootstrap";
import DropDownComponent from "../DropDownComponent";
import BaseDropDownItemsComponent from "../../../../components/BaseDropDownItemsComponent";
import BaseSearchInputComponent from "../../../../components/BaseSearchInputComponent";
import BaseRadioButtonGroup from "../../../../components/BaseRadioButtonGroup";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { ModalStopperComponent } from "../ModalStopperComponent";
import { ViewStepOneComponent } from "./ViewStepOneComponent";
import { ModalVerificationPhone } from "../Modals/ModalVerificationPhone";
import SuccessComponent from "../../SuccessComponent";
import { useSelector } from "react-redux";

export const CarouselComponent = ({
  dataYears,
  selectedButton,
  isMobile,
  handleOptionSelect,
  handleItemClick,
  handleButtonClick,
  selectedOptions,
  setSelectedOptions,
  selectedItem,
  isGnc,
  setIsGnc,
  number,
  dataBrands,
  dataBrandsSearch,
  dataModels,
  dataModelsSearch,
  setDataBrands,
  setDataModels,
  dataVersionsSearch,
  dataVersions,
  setDataVersions,
  handleInputChange,
  setShowModalForm,
  setNumber,
  setShowDropdownKms,
  currentView,
  setCurrentView,
  handleStartClick
}) => {

  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [showModalStopper, setShowModalStopper] = useState(false);
  const [priceQuote, setPriceQuote] = useState();

  const isFormValid = isGnc !== "" && number !== "";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getInWithEmail, setGetInWithEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);

  const toggleLoginModal = () => {
    setIsModalOpen(!isModalOpen);
    setGetInWithEmail(false);
    setShowLogin(false);
  };

  return (
    <>
      <Carousel
        className="carousel-sale"
        controls={false}
        indicators={false}
        interval={null}
        activeIndex={selectedButton - 1}
      >
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿De qué año es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("year", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(2);
            }}
            isDisabled={false} // Siempre habilitado en el primer paso
            selectedOptions={selectedOptions?.year?.name}
          >
            <BaseDropDownItemsComponent
              items={dataYears}
              onItemClick={handleItemClick}
              selectedItem={selectedItem}
              isYear={true}
              setSelectedOptions={setSelectedOptions}
              dataSelectedOptions={selectedOptions}
              type={"year"}
            />
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué marca es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("brand", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(3);
            }}
            isDisabled={!selectedOptions?.year}
            selectedOptions={selectedOptions?.brand?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la marca"}
              dataSearch={dataBrandsSearch}
              setDataSearch={setDataBrands}
            />

            {!dataBrands ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataBrands}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"brand"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué modelo es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("model", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(4);
            }}
            isDisabled={!selectedOptions?.brand}
            selectedOptions={selectedOptions?.model?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá el modelo"}
              dataSearch={dataModelsSearch}
              setDataSearch={setDataModels}
            />
            {!dataModels ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataModels}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"model"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué versión es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("version", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(5);
            }}
            isDisabled={!selectedOptions?.model}
            selectedOptions={selectedOptions?.version?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la version"}
              dataSearch={dataVersionsSearch}
              setDataSearch={setDataVersions}
            />
            {!dataVersions ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "85%" }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: " #7340d8",
                  }}
                />
              </div>
            ) : (
              <BaseDropDownItemsComponent
                items={dataVersions}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"version"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            textButton={"¿Cuántos kms tiene tu auto?"}
            onItemClick={(selectedItem) => {
              handleOptionSelect("kms", selectedItem);
              handleItemClick(selectedItem);
              handleButtonClick(6);
            }}
            isDisabled={!selectedOptions?.version}
          >
            <input
              type="text"
              className="base-input mt-3 mb-3 text-center"
              value={number}
              onChange={handleInputChange}
              placeholder="Ej: 24.000"
            />
            <div
              style={{
                display: "flex",
                height: "290px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: " space-between",
              }}
            >
              <BaseRadioButtonGroup
                isGnc={isGnc}
                setIsGnc={setIsGnc}
                kms={number}
                version_id={selectedOptions?.version?.id}
                setShowModalStopper={setShowModalStopper}
              />
              <div>
                <BasePurpleButtonComponent
                  buttonText={"Continuar"}
                  onClick={() => {
                    handleButtonClick(6);
                  }}
                  buttonDisabled={!isFormValid}
                />
              </div>
            </div>
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          {currentView === "step1" ? (
            <ViewStepOneComponent
              selectedOptions={selectedOptions}
              isGnc={isGnc}
              kms={number}
              setCurrentView={setCurrentView}
              setPriceQuote={setPriceQuote}
              setPhoneNumber={setPhoneNumber}
            />
          ) : currentView === "step2" ? (
            <ModalVerificationPhone
              dataCar={selectedOptions}
              isGnc={isGnc}
              kms={number}
              textError={"Código inválido"}
              setCurrentView={setCurrentView}
              setPriceQuote={setPriceQuote}
              phoneNumber={phoneNumber}
            />
          ) : currentView === "step3" ? (
            <div className="mt-2" style={{ height: "100%" }}>
              <SuccessComponent
                handleClick={handleStartClick}
                isMobile={true}
                isRenewal={true}
                priceQuote={priceQuote}
                brand={selectedOptions?.brand.name}
                model={selectedOptions?.model.name}
                version={selectedOptions?.version.name}
                year={selectedOptions?.year.name}
                kms={number}
              />
            </div>
          ) : null}
        </Carousel.Item>
      </Carousel>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setSelectedOptions={setSelectedOptions}
        setNumber={setNumber}
        setIsGnc={setIsGnc}
        isMobile={true}
        setShowDropdownKms={setShowDropdownKms}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};
