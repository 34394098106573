import axios from "axios";
import { types } from "../types/types";
import qs from "qs";
import { login } from "./auth";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUser = (token, dispatch) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  dispatch(setUser({ verified_phone: null }));
  axios
    .get(`${BASE_URL}/users/get_user`, {
      headers,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(resp.data));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const createUser = async (name, email, phone, token) => {
  axios
    .post(
      `${BASE_URL}/users/create_user`,
      { phone: phone, name: name, email: email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      console.log(resp);
      //   setIsLoading(false);
      //   setCurrentView("step3");
    })
    .catch((e) => {
      console.log(e.response.data.message);
      //   console.log(e);
      // const error = JSON.parse(e.request.response);
      // console.log(error);
    });
  //   try {
  //     const result = await post(
  //       "/users/create_user",
  //       { phone: phone, name: name, email: email },
  //       token
  //     );
  //     console.log("Solicitud POST exitosa:", result);
  //     // setAuthenticated(true);
  //   } catch (error) {
  //     console.error("Error al realizar la solicitud POST:", error.message);
  //   }
};

export const updateUser = (token, payload, dispatch) => {
  axios
    .post(`${BASE_URL}/users/update_user`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(payload));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

// Services by phone

export const getUserByPhone = (phone, setIsLoading, dispatch) => {
  dispatch(setUser({ verified_phone: null }));
  axios
    .get(`${BASE_URL}/users/get_user_by_phone?phone=${phone}`)
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(resp.data));
      dispatch(login(
        undefined,
        resp?.data?.name,
        resp?.data?.email,
        true
      ));
      setIsLoading(false);
    })
    .catch((e) => {
      console.log(e.response.data.message);
      createUserByPhone(undefined, undefined, phone, setIsLoading, dispatch);
    });
};

export const createUserByPhone = async (name, email, phone, setIsLoading, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/create_user_by_phone`,
      { phone, name: name? name: "", email: email? email: "" },
    )
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(resp.data.user));
      dispatch(login(
        undefined,
        resp?.data?.user?.name,
        resp?.data?.user?.email,
        true,
        undefined
      ));
      dispatch(setUser({ verified_phone: undefined }));
      setIsLoading(false);
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const updateUserByPhone = (payload, dispatch) => {
  axios
    .post(`${BASE_URL}/users/update_user_by_phone`, payload)
    .then((resp) => {
      console.log(resp);
      dispatch(setUser(payload));
      dispatch(login(
        undefined,
        payload.name,
        payload.email,
        true,
        undefined
      ));
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const activeUserServer = async () => {
  axios
    .get(`${BASE_URL}/users/activate`)
    .then((resp) => {
      console.log(resp);
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
};

export const setUser = (data) => ({
  type: types.setUser,
  payload: data,
});
