import axios from "axios";
import { setUser, updateUser, updateUserByPhone } from "./user";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const sendPhoneCode = (token, phone, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/update_user`,
      { phone },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => {
      dispatch(setUser({ phone }));
      axios
        .post(
          `${BASE_URL}/users/send_check_code`,
          { channel: "compras" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((e) => {
          console.log(e.response.data.message);
        });
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
}

export const checkPhoneCode = async (token, code, setisError, dispatch, setIsLoading, setCurrentView) => {
  return axios
    .post(
      `${BASE_URL}/users/check_phone`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      console.log(resp);
      updateUser(token, { verified_phone: true }, dispatch);
      if(setCurrentView) setCurrentView("step3")
      setisError(false);
      return true
    })
    .catch((e) => {
      console.log(e?.response?.data?.message);
      setisError(true);
      setIsLoading(false);
      return false
    });
}

// Services by phone:

export const sendPhoneCodeByPhone = (phone, dispatch) => {
  axios
    .post(
      `${BASE_URL}/users/update_user_by_phone`,
      { phone }
    )
    .then(() => {
      dispatch(setUser({ phone }));
      axios
        .post( `${BASE_URL}/users/send_check_code_by_phone?channel=compras&phone=${phone}` )
        .then((resp) => {
          console.log(resp);
        })
        .catch((e) => {
          console.log(e.response.data.message);
        });
    })
    .catch((e) => {
      console.log(e.response.data.message);
    });
}

export const checkPhoneCodeByPhone = async (phone, code, setisError, dispatch, setIsLoading, setCurrentView) => {
  return axios
    .post( `${BASE_URL}/users/check_phone_by_phone?code=${code}&phone=${phone}` )
    .then((resp) => {
      console.log(resp);
      updateUserByPhone({ phone, verified_phone: true }, dispatch);
      if(setCurrentView) setCurrentView("step3")
      setisError(false);
      return true
    })
    .catch((e) => {
      console.log(e?.response?.data?.message);
      setisError(true);
      setIsLoading(false);
      return false
    });
}
